import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo />
      {/* <Img fixed={data.file.childImageSharp.fluid} /> */}
      <div className="greyborderbottom">
        <StaticImage src="../../content/img/pearl-harbor-banner-image.jpeg" alt="pearl harbor entrance" />
      </div>
      <div className="max-w-screen-lg grid gap-6 m-auto py-6">
        <div className="bg-white shadow-lg p-8 my-8 min-w-full w-full prose homecard">
          <h1>Welcome to Pearl Harbor</h1>
          Pearl Harbor is the #1 most visited site in Hawaii.
          <p>There are a variety of ways to visit Pearl Harbor and a wealth of information to explore. This is your one stop guide to planning your experience at Pearl Harbor.</p>
          <p>
            There are four different attractions at Pearl Harbor:
            <ul>
              <li>
                <a href="/sites-and-history/uss-arizona-memorial">The USS Arizona (BB-39) Battleship</a>
              </li>
              <li>
                <a href="/sites-and-history/uss-arizona-memorial">The Arizona Memorial</a>
              </li>
              <li>
                <a href="/sites-and-history/uss-bowfin-submarine">The USS Bowfin (SS-287) submarine and Museum</a>
              </li>
              <li>
                <a href="/sites-and-history/uss-missouri-battleship">The USS Missouri (BB-63) Battleship</a>
              </li>
            </ul>
          </p>
          <p>Allow us to be your guide as you navigate the rich history of this tragic site.</p>
          <p>Bookmark us for the best source of information for all upcoming events, closures, tours, tickets, history, and more.</p>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "content/img/custom-tour-01.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
